import React from "react";
import Team from "../../Components/Molecules/Team/Team";
import Clark from "../../assets/images/TEAM/Clark.jpg";
import Jeck from "../../assets/images/TEAM/Jeck.jpg";
import Leo from "../../assets/images/TEAM/Leo.jpg";
import Andrea from "../../assets/images/TEAM/Andrea.jpg";
import Luca from "../../assets/images/TEAM/Luca.jpg";
import ChiaraL from "../../assets/images/TEAM/ChiaraL.jpg";
import ChiaraV from "../../assets/images/TEAM/ChiaraV.jpg";
import Christian from "../../assets/images/TEAM/Christian.jpg";
import Brunella from "../../assets/images/TEAM/Brunella.jpg";
import { motion } from "framer-motion";

function TeamPage() {
	return (
		<div className=" text-bianco md:px-44 my-32 pt-10">
			<div className="">
				<motion.h2
					className="text-6xl font-bold uppercase"
					initial={{ x: "-100vw" }}
					animate={{ x: 0 }}
					transition={{ type: "spring", stiffness: 30 }}
				>
					il nostro team
				</motion.h2>
				<div className="grid md:grid-cols-2 grid-cols-1  w-full mt-20  gap-y-10">
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="grid col-span-full md:grid-cols-2 grid-cols-1  w-full mt-20  gap-y-10"
					>
						<Team
							name="Jeck"
							surname="scardy"
							role="CEO & founder"
							img={Jeck}
						/>
						<Team
							name="andrea"
							surname="carlone"
							role="COO & Project Manager"
							img={Andrea}
						/>
					</motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="w-full bg-bianco h-[1px] my-3 hidden md:block md:col-span-2"
					></motion.div>

					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="grid col-span-full md:grid-cols-2 grid-cols-1  w-full mt-20  gap-y-10"
					>
						<Team
							name="chiara"
							surname="lombardino"
							role="Community Manager & Social Media Manager"
							img={ChiaraL}
						/>
						<Team
							name="clark"
							surname="guia"
							role="editor & designer"
							img={Clark}
						/>
					</motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="w-full bg-bianco h-[1px] hidden md:block my-3 col-span-2"
					></motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="grid col-span-full md:grid-cols-2 grid-cols-1  w-full mt-20  gap-y-10"
					>
						<Team
							name="leonardo"
							surname="picollo"
							role="3d Supervisor & Project Manager"
							img={Leo}
						/>
						<Team
							name="luca"
							surname="lombardino"
							role="Primary Content Creator"
							img={Luca}
						/>
					</motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="w-full bg-bianco h-[1px] hidden md:block my-3 col-span-2"
					></motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="grid col-span-full md:grid-cols-2 grid-cols-1  w-full mt-20  gap-y-10"
					>
						<Team
							name="chiara"
							surname="vincenzi"
							role="Project Manager"
							img={ChiaraV}
						/>
						<Team
							name="christian"
							surname="Della Torre"
							role="Strategist & Marketing Manager"
							img={Christian}
						/>
					</motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="w-full bg-bianco h-[1px] hidden md:block my-3 col-span-2"
					></motion.div>
					<motion.div
						initial={{ x: "-100vw" }}
						animate={{ x: 0 }}
						transition={{ type: "spring", stiffness: 30 }}
						className="grid col-span-full md:grid-cols-2 grid-cols-1  w-full mt-20  gap-y-10"
					>
						<Team
							name="brunella"
							surname="manelli"
							role="Accountant"
							img={Brunella}
						/>
						
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default TeamPage;
